export class AbstractPanel {

    subscribers = [];
    client;
    locations = [];

    ea

    config = {
        routeColumnsConfig: {},
        routeItemColumnsConfig: {},
        journeyLocationColumnsConfig: {}
    };

    constructor(client, ea) {
        this.client = client;
        this.ea = ea;
    }

    /**
     * update items by ids
     * @param newItems
     * @param oldItems
     * @param deleteItems
     * @returns {*}
     */
    updateItemsById(newItems, oldItems, deleteItems = false) {
        newItems.forEach(item => {
            let index = oldItems.findIndex(element => element.id === item.id)
            if (index === -1) {
                oldItems.push(item);
            } else {
                oldItems.splice(index, 1, item);
            }
        })

        if (deleteItems) {
            oldItems = oldItems.filter(item => {
                return newItems.findIndex(element => element.id === item.id) !== -1
            })
        }
        return oldItems;
    }


    _getColumnConfigForReferenceField(field) {
        return this.config.locationColumnsConfig.find(config => config.property === field);
    }

    _getLocationPanelClass(location) {

        let classes = [];

        if (typeof location === 'undefined') {
            return classes;
        }

        classes.push('panel-default');

        if (location.locationType.includes('departure')) {
            classes.push('departure');
            if (location.participantCount == 0) {
                classes.push('panel-danger');
            } else {

            }
        }

        if (location.locationType.includes('arrival')) {
            classes.push('arrival');
            classes.push('panel-info')
        }

        return classes;
    }
}
