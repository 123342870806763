import {bindable, inject} from 'aurelia-framework';
import {Client} from "../../../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";

import {StandardActions} from "../../../action/standard-actions";
import {ConfigurationLoader} from "../../../table/loader/configuration-loader";
import {AbstractPanel} from "./abstract-panel";

@inject(Client, EventAggregator, BindingSignaler, StandardActions, ConfigurationLoader)

export class RouteItems extends AbstractPanel {

    @bindable route;
    @bindable reference;

    config;
    items = [];

    client;
    ea;

    constructor(client, ea, signaler, actions, configurationLoader) {
        super(client, ea);
        this.client = client
        this.ea = ea;
        this.signaler = signaler;
        this.actions = actions;
        this.configurationLoader = configurationLoader;
    }

    attached() {
        this.viewReady = true;
    }

    detached() {
        this.viewReady = false;
        this.sortable = null;
    }

    async bind() {
        await this.updateConfig();
        if (this.route !== null) {
            await this.fetchItems(this.route);
        }
    }

    async fetchItems(route) {

        let conditions = {
            includeArchived: false,
            route: {
                $eq: {
                    id: route.id,
                    modelId: route.modelId
                }
            },
            routeItinerary: {
                $eq: {
                    id: route.reference.id,
                    modelId: route.reference.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus-routing/route-item?conditions=' + JSON.stringify(conditions) + '&embeds[]=data');

        let items = await response.items;

        items.sort(function (a, b) {
            return a.weight - b.weight;
        })

        this.items = items;
    }

    _getColumnConfigForField(field) {
        return this.config.find(config => config.property === field);
    }

    async updateConfig() {
        const data = await this.configurationLoader.get('tourism-bus-routing/route-item', null);
        this.config = await data.columns;
    }

    async onDoubleClick(item, route) {

        if (route.locked == true) {
            return;
        }

        let context = {
            id: item.id,
            modelId: item.modelId,
        };

        await this.actions.getAction({
            type: 'view',
            icon: 'fa fa-pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'tourism-bus-routing/manage-route-item',
            bulk: false,
            modal: true
        }, context).action();
    }

    onRemoveItemClick(route, item) {

        this.ea.publish('sio_routing.routes.item.onDelete', {
            routeId: route.id,
            itemId: item.id,
            modelId: item.modelId
        });

    }

    onToggleCalculationClick(item) {
        let action = {
            type: "workflow",
            workflowId: "tourism-bus-routing/route-toggle-calculation",
        }

        let context = {
            id: item.id,
            modelId: item.modelId,
        };

        this.actions.getAction(action, context).action();
    }
}
