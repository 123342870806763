import {bindable, computedFrom} from "aurelia-framework";

export class Participant {
    @bindable assignment;

    get order() {
        if (!this.assignment.item) {
            return null;
        } else {
            return this.assignment.item.order;
        }
    }

    @computedFrom('assignment')
    get participants() {
        if (!this.assignment.item.participants) {
            return null;
        } else {
            return this.assignment.item.participants
        }
    }

    @computedFrom('assignment')
    get message() {
        if (!this.assignment.message) {
            return false;
        } else {
            return this.assignment.message;
        }
    }
}
