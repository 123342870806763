import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {I18N} from "aurelia-i18n";
import {FlashService} from "../../flash/flash-service";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import {DialogService} from "aurelia-dialog";
import {StandardActions} from "../../action/standard-actions";
import {WorkflowService} from "../../workflow/workflow-service";
import {ConfigurationLoader} from "../../table/loader/configuration-loader";
import {RendererService} from "../../table/service/renderer-service";

import "./bus-routing-participant-view.less";

@customElement('sio-tourism-bus-routing-participant-view')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler, DialogService, StandardActions, WorkflowService, ConfigurationLoader, RendererService)
export class BusRoutingParticipantView {

    @bindable journey;
    @bindable options

    constructor(client, i18n, flash, ea, signaler, dialogService, standardActions, workflowService, configurationLoader, renderService) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
        this.dialogService = dialogService;
        this.actions = standardActions;
        this.workflowService = workflowService;
        this.configLoader = configurationLoader;
        this.renderService = renderService;
    }

    async attached() {
        this.loading = true;

        this.loading = false;
    }

    async bind() {

    }

    async updateRouteItemConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-route-item', null);
        this.configItemColumns = await data.columns;
    }


    async updateRouteItems() {
        let conditions = {
            journey: {
                $eq: {
                    id: this.journey.id,
                    modelId: this.journey.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus/bus-routing-route-item?conditions=' + JSON.stringify(conditions));
        this.routeItems = await response.items;
    }
}
