import {bindable, customElement, inject, LogManager} from "aurelia-framework";
import {Client} from "../../../api/client";
import {I18N} from 'aurelia-i18n';
import {DialogService} from "aurelia-dialog";
import {EventAggregator} from "aurelia-event-aggregator";
import {FlashService} from "../../../flash/flash-service";
import * as _ from "lodash";

import "./participant-assignment.less";
import {BindingSignaler} from "aurelia-templating-resources";

const logger = LogManager.getLogger('ManageEquipment');
logger.setLevel(LogManager.logLevel.none); // Disable logging for this class by setting "LogManager.logLevel.none"

@customElement('sio-tourism-bus-routing-participant-assignment')
@inject(Client, I18N, FlashService, EventAggregator, BindingSignaler, DialogService)

export class ParticipantAssignment {

    @bindable route;

    choices = [];
    columns = [];
    assigned = [];
    unassigned = [];

    sortable = [];

    viewReady = false;
    sortingInitialized = false;

    propertyList = [
        'order',
        'participants',
    ];

    constructor(client, i18n, flash, ea, signaler, dialogService) {
        this.client = client;
        this.i18n = i18n;
        this.flash = flash;
        this.ea = ea;
        this.signaler = signaler;
        this.dialogService = dialogService;
    }

    attached() {
        this.viewReady = true;
    }

    detached() {
        this.viewReady = false;
        this.setChanged(false);
    }

    async fetchColumns() {
        let list = await this.client.get('order/order-item/list');
        this.columns = list.columns.filter(column => {
            return this.propertyList.includes(column.property);
        });
        this.columnsFetched = true;
    }

    _getColumn(property) {
        return _.find(this.columns, (column) => {
            return column.property === property;
        });
    }

    async routeChanged() {

        await this.fetchColumns(this.route);

        let data = await this.client
            .get('tourism-bus-routing/route/participant/assignment/' + this.route.id)

        let choices = data.choices;

        this.processChoices(choices);
    }

    processChoices(choices) {
        this.assigned = choices.filter((choice) => (choice.route)).map((choice) => (choice.id));
        this.unassigned = choices.filter((choice) => (!choice.route)).map((choice) => (choice.id));
        this.choices = choices;
    }

    _getChoice(id) {
        return _.find(this.choices, (choice) => {
            return choice.id === id;
        });
    }

    _translate(message) {
        return this.i18n.tr(message);
    }

    swapItem(choice) {
        if (this.route.locked) {
            return;
        }

        let assigned = this.assigned.filter((id) => id !== choice);
        let unassigned = this.unassigned.filter((id) => id !== choice);

        let list = this.assigned.includes(choice) ? 'assigned' : 'unassigned';

        if (list === 'unassigned') {
            assigned.push(choice);
        } else {
            unassigned.push(choice);
        }

        this.assigned = assigned;
        this.unassigned = unassigned;

        this.setChanged(true);
    }

    setChanged(changed) {
        if (changed) {
            if (null != this.changesKey) {
                return; // No need to notify several times
            }

            this.changesKey = 'tourism-bus-routing/route-participant-manager__' + new Date();
            this.ea.publish('sio_register_unsaved_changes', {changesKey: this.changesKey});
        } else {

            this.ea.publish('sio_unregister_unsaved_changes', {changesKey: this.changesKey});
            this.changesKey = null;
        }
    }

    async save() {


        let participantAssignments = {
            unassigned: this.unassigned.map(item => {
                return {
                    orderItem: {id: item, modelId: 'order/order-item'},
                    selectedRouteSegmentType: null,
                    selectedRouteSegmentToken: null,
                }
            }),
            assigned: this.assigned.map(item => {
                let choice = _.find(this.choices, (choice) => choice.id);
                return {
                    orderItem: {id: item, modelId: 'order/order-item'},
                    selectedRouteSegmentType: choice.selectedRouteSegmentType ?? null,
                    selectedRouteSegmentToken: choice.selectedRouteSegmentToken ?? null,
                }
            }),
        };


        let data = await this.client.put('tourism-bus-routing/route/participant/assignment/' + this.route.id, participantAssignments);

        this.flash.success('form.success');
        this.setChanged(false);

        let choices = data.choices;

        this.processChoices(choices);
    }
}
