import ReactDOM from 'react-dom';
import {bindable, customElement, inject, noView} from 'aurelia-framework';

import RoutingMap from './maps/routing-map';
import {GoogleApiWrapper} from "google-maps-react";
import {Client} from "../../api/client";

@noView()
@inject(Element, Client)
@customElement('sio-tourism-bus-routing-map')
export class BusRoutingMap {

    @bindable data
    reactComponent = {};
    client;
    apikey = '';
    language = '';

    constructor(element, client) {
        this.element = element;
        this.client = client;
    }

    async render() {

        let addressMarkers = async (data) => {
            let markers = [];
            let locations = data?.routeLocations ?? data?.locations ?? [];

            for (const location of locations) {

                let data;
                if (
                    location?.title
                    && location?.address
                    && location?.coordinates
                ) {
                    data = Promise.resolve(location);
                } else {
                    const {modelId, id} = location;
                    data = await this.client.get(modelId + '/' + id);
                }


                let {title, address} = data;

                let {coordinates} = address;

                title = title.concat('\n' + address.addressLine1);
                if (address.country !== undefined) {
                    title = title.concat(', ' + address.country);
                    title = title.concat(' ' + address.zip + ' ' + address.city);
                } else {
                    title = title.concat(', ' + address.zip + ' ' + address.city);
                }
                if (address.region !== undefined) {
                    title = title.concat('\n' + address.region);
                }

                markers.push({
                    lat: coordinates.lat,
                    lng: coordinates.lng,
                    title: title
                })
            }
            return markers;
        }


        let polylines = (data) => {
            if (data.directionPolylines === undefined || data.directionPolylines.length === 0) return [];

            let polylines = [];

            for (let item of data.directionPolylines) {
                if (item.polyline !== undefined) {
                    polylines.push(item.polyline);
                }
            }

            if (polylines.length === 0) return [];

            return polylines;
        }

        let markers = await addressMarkers(this.data);

        this.reactComponent = ReactDOM.render(
            <GoogleRoutingMap
                data={this.data}
                polylines={polylines(this.data)}
                markers={markers}
                apiKey={this.apikey}
                language={this.language}/>,
            this.element
        );


    }

    async bind() {

        let settings = await this.client
            .get('maps/google/apikey');

        if (settings !== undefined) {
            this.apikey = settings.apiKey ?? '';
            this.language = settings.locale ?? 'en';
        }

        this.render();


    }

    dataChanged(newVal) {
        this.bind();
    }

}

const GoogleRoutingMap = GoogleApiWrapper(
    (props) => ({
            apiKey: props.apiKey,
            language: props.language,
        }
    ))(RoutingMap)
