import {
    bindable,
    bindingMode,
    customElement,
    inject,
} from "aurelia-framework";
import { Client } from "../api/client";
import { ConfigurationLoader } from "../form/loader/configuration-loader";
import { FlashService } from "../flash/flash-service";
import { UrlUtils } from "../utilities/url-utils";
import { DialogService } from "aurelia-dialog";
import { SubmitHandler } from "../form/submit/submit-handler";
import { AuthTokenStorage } from "../auth/auth-token-storage";
import { AureliaConfiguration } from "aurelia-configuration";
import { render, unmountComponentAtNode } from "react-dom";
import React from "react";
import { FastBookingrender } from "./fast-booking/main";

import "./fast-booking/fast-booking.less";
@customElement("sio-toma-mask")
@inject(
    Element,
    Client,
    ConfigurationLoader,
    FlashService,
    UrlUtils,
    DialogService,
    SubmitHandler,
    AuthTokenStorage,
    AureliaConfiguration
)
export class TomaMask {
    constructor(
        element,
        client,
        configLoader,
        flash,
        urlUtils,
        dialog,
        apiSubmit,
        token,
        aureliaConfig
    ) {
        this.element = element;
        this.client = client;
        this.configLoader = configLoader;
        this.flash = flash;
        this.urlUtils = urlUtils;
        this.dialog = dialog;
        this.submit = apiSubmit;
        this.token = token;
        this.aureliaConfig = aureliaConfig;

    }

    render() {
        if (this.container) {
            const tomaMask ={
                crs:"string",
                operator: "FUV",
            }

            render(
                <FastBookingrender  tomaMask={tomaMask} client={this.client} />,
                this.container
            );
        }
    }

    attached() {
        this.container = document.getElementById("sio-toma-mask");
        this.render();
    }

    detached() {
        unmountComponentAtNode(this.container);
    }

}
