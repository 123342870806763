import {bindable, inject} from 'aurelia-framework';
import {Client} from "../../../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";
import Sortable from "sortablejs";

import "./routes-panel.less"
import {StandardActions} from "../../../action/standard-actions";
import {AbstractPanel} from "./abstract-panel";
import moment from "moment-timezone";
import {ConfigurationLoader} from "../../../table/loader/configuration-loader";

@inject(Client, EventAggregator, BindingSignaler, StandardActions, ConfigurationLoader)
export class RoutesPanelCustomElement extends AbstractPanel {

    @bindable reference
    @bindable routes;
    @bindable locations;

    constructor(client, ea, signaler, actions, configurationLoader) {
        super(client, ea);
        this.client = client;
        this.signaler = signaler;
        this.actions = actions;
        this.configurationLoader = configurationLoader;
    }

    async attached() {
        await this.updateConfig();
        this.eventListener();
        this.viewReady = true;
    }

    detached() {
        this.subscribers.forEach(subscriber => {
            subscriber.dispose();
        })
        this.viewReady = false;
        this.sortable = null;
    }

    eventListener() {

        this.subscribers.push(this.ea.subscribe('sio_routing.reload.items.success', evt => this._updateRoutesSorting()));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.sort.onAdd', evt => this.onAddEvent(evt)));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.sort.onUpdate', evt => this.onUpdateEvent(evt)));

        this.subscribers.push(this.ea.subscribe('sio_routing.routes.item.onDelete', evt => this.onDeleteEvent(evt)));
    }

    async onAddEvent(evt) {
        let dest = evt.to;
        let item = evt.item;

        let index = evt.newIndex;

        let update = {
            route: {
                modelId: 'tourism-bus-routing/route',
                id: dest.dataset.routeId
            },
            weight: index,
            item: {
                modelId: item.dataset.modelId,
                id: item.dataset.itemId
            }
        };

        await this.client.post('tourism-bus-routing/route/assignment-update', update);
        this.ea.publish('sio_routing.reload.items', {config: {modelId: this.reference.modelId}});
    }

    async onUpdateEvent(evt) {
        let dest = evt.to;
        let item = evt.item;
        let promises = [];

        if (evt.newIndex === evt.oldIndex) {
            return;
        }

        if (dest.dataset.routeId !== "") {

            let update = {
                route: {
                    modelId: 'tourism-bus-routing/route',
                    id: dest.dataset.routeId
                },
                weight: evt.newIndex,
                item: {
                    modelId: item.dataset.modelId,
                    id: item.dataset.itemId
                }
            };

            await this.client.post('tourism-bus-routing/route/assignment-update', update);
            this.ea.publish('sio_routing.reload.items', {config: {modelId: this.reference.modelId}});
        }
    }

    async onDeleteEvent(evt) {
        let update = {
            item: {
                modelId: evt.modelId,
                id: evt.itemId
            }
        };

        await this.client.post('tourism-bus-routing/route/assignment-update', update);
        this.ea.publish('sio_routing.reload.items', {config: {modelId: this.reference.modelId}});
    }

    _updateRoutesSorting() {

        this.routes.forEach((route) => {

            let routes = {
                name: 'routes',
                // put: false|
                put: ['routes', 'unassignedJobs']
            };
            this._initializeRouting('route-' + route.id, routes, true)

            let addItem = {
                name: 'addItem',
                put: ['routes', 'unassignedJobs']
            }
            this._initializeRouting('addLocation-' + route.id, addItem, true)

        })
    }

    _initializeRouting(identifier, group, sort = false) {

        this.sortable = Sortable.create(document.getElementById(identifier), {
            group: group,
            forceFallback: true,
            sort: sort,
            filter: '.no-drag',
            dataIdAttr: 'data-location-id',

            onMove: function (evt) {
                return evt.related.className.indexOf('no-drag') === -1;
            },

            onAdd: (evt) => {
                this.ea.publish('sio_routing.routes.item.sort.onAdd', evt);
            },
            onUpdate: evt => {
                this.ea.publish('sio_routing.routes.item.sort.onUpdate', evt);
            }
        });
    }


    _getColumnConfigForField(field) {
        return this.config.find(config => config.property === field);
    }

    _showRouteActions(action, route) {
        if (action.preset == 'display') {
            return true;
        }

        if (action.preset == 'delete'
            && this.routes.length > 0
            && (this.reference.overwriteTemplate
            || !this.reference.templateReference)) {
            return true;
        }
    }


    _showRouteTime(time) {
        // if (time !== undefined) {
        //     return time.slice(0, 2) + ':' + time.slice(2)
        // }
        return time;
    }

    _getDate(date) {
        return moment(date).format('DD.MM.YY HH:mm');
    }

    async updateConfig() {
        const data = await this.configurationLoader.get('tourism-bus-routing/route', null);
        this.config = await data.columns;
    }

    async onDoubleClick(route) {

        if (route.locked == true || (this.reference.templateReference && !this.reference.overwriteTemplate)) {
            return;
        }

        let context = {
            id: route.id,
            modelId: route.modelId,
        };

        await this.actions.getAction({
            type: 'view',
            icon: 'fa fa-pencil',
            buttonClass: 'btn btn-danger btn-xs',
            viewId: 'tourism-bus-routing/manage-route-overview',
            bulk: false,
            modal: true
        }, context).action();
    }

}
