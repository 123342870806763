import {bindable, inject} from "aurelia-framework";


import "./route-elements.less";
import {Client} from "../../../api/client";
import {ConfigurationLoader} from "../../../table/loader/configuration-loader";
import {BindingSignaler} from "aurelia-templating-resources";


@inject(Client, ConfigurationLoader, BindingSignaler)
export class RouteElements {
    @bindable journey

    onlyConfirmed = false;

    constructor(client, configLoader, signaler) {
        this.client = client;
        this.configLoader = configLoader;
        this.signaler = signaler;
    }

    async attached() {
        this.loading = true;
        await this.fetchColumnConfig();
        await this.fetchRoutes();
        this.loading = false;
    }

    async bind() {

    }

    async fetchColumnConfig() {
        const data = await this.configLoader.get('tourism-bus/bus-routing-route', null);
        this.columnConfig = await data.columns;
    }

    async fetchRoutes() {
        let conditions = {
            includeArchived: false,
            journey: {
                $eq: {
                    id: this.journey.id,
                    modelId: this.journey.modelId
                }
            }
        };

        let response = await this.client.get('tourism-bus/bus-routing-route?conditions=' + JSON.stringify(conditions));
        let routes = await response.items;
        routes.sort(function (a, b) {
            return a.routeId - b.routeId;
        });

        this.routes = routes;
        console.log(this.routes, 'ROUTES');
    }

    _getColumnConfigForField(field) {
        return this.columnConfig.find(config => config.property === field);
    }

    showOnlyConfirmed(event) {
        console.log(event)
        this.onlyConfirmed = event;
        this.signaler.signal('showOnlyConfirmed');
    }
}
