import React from "react";
import ReactDOM from "react-dom";
import FastBookingForm from "./components/FastBookingForm";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { reducer } from "redux-form";



const rootReducer = combineReducers({
    form: reducer,
});
const store = createStore(rootReducer);

export const FastBookingrender = ({ className, tomaMask,client, ...props }) => {
    return (
        <Provider store={store}>
            <div className={`${className ?? ""} fast-booking-form`}>
                <FastBookingForm tomaMask={tomaMask} client={client}/>
            </div>
        </Provider>
    );
};

const fastBookingInitiate = (container, props) => {
    if (container) {
        const tomaMask = container.dataset.tomaMask ? JSON.parse(container.dataset.tomaMask) : {};
        ReactDOM.render(<FastBookingrender {...props} tomaMask={tomaMask} />, container);
    }
};

export default fastBookingInitiate;