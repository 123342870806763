import {bindable, customElement, inject} from "aurelia-framework";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {UserClient} from "../api/user-client";
import {DialogService} from "aurelia-dialog";
import Client from "../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {BindingSignaler} from "aurelia-templating-resources";

@inject(FormServiceFactory, UserClient, DialogService, Client, EventAggregator, BindingSignaler)
@customElement('tourism-contract-accommodation-discount-type')

export class DiscountType {
    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    type;
    ageUntil;
    condition;
    conditionSet;

    constructor(formServiceFactory, user, dialogService, client, ea, signaler) {
        this.formServiceFactory = formServiceFactory;
        this.dialogService = dialogService;
        this.client = client;
        this.ea = ea;
        this.signaler = signaler;
    }

    _getControlUID() {
        return 'tourism-contract-accommodation-discount-type-' + this.field.property;
    }

    attached() {
        this.viewChange = true;
        this.value = this.updateModelValue();
    }

    async bind() {
        this._processModelValue();
    }

    detached() {
    }

    valueChanged(newValue) {
        //Important check here, as a view change also triggers this callback and this way we would have a loop
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._processModelValue();
    }

    _processModelValue() {
        this.type = this.value?.type;
        this.ageUntil = this.value?.ageUntil;
        this.condition = this.value?.condition;
    }

    updateAndSaveValue() {
        this.viewChange = true;

        this.value = this.updateModelValue();
    }

    updateModelValue() {

        switch (this.type) {
            case 'single-with-child':
                this.conditionSet = 'tourism_contract_accommodation_discount_type_single-with-child'
                this.signaler.signal('tourism_contract_accommodation_discount_updated');
                break;
            case 'children':
            case 'first-child':
            case 'second-child':
                this.conditionSet = 'tourism_contract_accommodation_discount_type_children'
                this.signaler.signal('tourism_contract_accommodation_discount_updated');
                break;
            default:
                this.conditionSet = null;
                this.ageUntil = null;
                this.condition = null;
                this.signaler.signal('tourism_contract_accommodation_discount_updated');
        }

        return {
            type: this.type,
            ageUntil: this.ageUntil,
            condition: this.condition,
        };
    }
}
