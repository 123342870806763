import React from 'react';
import {Map, Marker, Polyline} from "google-maps-react";

export default class RoutingMap extends React.PureComponent {

    decodePolyline = (encoded) => {

        // array that holds the points

        var points = []
        var index = 0, len = encoded.length;
        var lat = 0, lng = 0;
        while (index < len) {
            let b, shift = 0, result = 0;
            do {

                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);


            let dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
            lat += dlat;
            shift = 0;
            result = 0;
            do {
                b = encoded.charAt(index++).charCodeAt(0) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);
            let dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
            lng += dlng;

            points.push({lat: (lat / 1E5), lng: (lng / 1E5)})

        }
        return points
    }

    polylineBounds = (polylines) => {
        let locations = [];
        polylines.map(polyline => {
            locations.push(this.decodePolyline(polyline));
        })

        let bounds;

        if (locations.length > 0) {
            bounds = new window.google.maps.LatLngBounds();
            locations.flat().map(item => {
                bounds.extend(item);
                return item
            });
        }
        return bounds
    }

    markerBounds = (markers) => {

        let bounds = new window.google.maps.LatLngBounds();

        if (markers.length > 0) {
            markers.map(item => {
                bounds.extend({
                    lat: item.lat,
                    lng: item.lng
                });
                return item
            });
        } else {
            bounds.extend({
                lat: 50.142002,
                lng: 8.6073363
            })
            bounds.extend({
                lat: 50.079344,
                lng: 8.779646
            })
        }
        return bounds
    }

    zoomfactor = (minLngWest, maxLngEast) => {
        let globe_width = 256;
        let angel = maxLngEast - minLngWest;
        if (angel < 0) {
            angel += 360;
        }
        return Math.round(Math.log(960 * 360 / angel / globe_width) / Math.LN2) - 2;
    }

    render() {
        const {data, google, markers, polylines} = this.props;

        if (!data) return null;

        let bounds = this.polylineBounds(polylines);

        if (bounds === undefined) {
            bounds = this.markerBounds(markers);
        }


        return <Map
            mapType={'roadmap'}
            google={google}
            containerStyle={{width: '100%', height: '500px', position: 'relative'}}
            bounds={bounds}
            zoom={this.zoomfactor(bounds.getSouthWest().lng(), bounds.getNorthEast().lng())}
            initialCenter={{
                lat: bounds.getCenter()?.lat(),
                lng: bounds.getCenter()?.lng()
            }}
        >
            {markers.map((marker, index) => <Marker
                    key={index}
                    title={(marker.title !== undefined) ? marker.title : ''}
                    position={{
                        lat: marker.lat,
                        lng: marker.lng
                    }}
                />
            )}

            {polylines.map((polyline, index) => <Polyline
                key={index}
                path={this.decodePolyline(polyline)}
                strokeWeight={2}/>
            )}
        </Map>


    }
}


RoutingMap.defaultProps = {};


