import React from "react"
import {bindable, bindingMode, customElement, inject, noView} from "aurelia-framework";
import {render} from "react-dom";
import {Provider} from "react-redux";
import store from "../store/store";
import {useContingentPoolQuery} from "../store/api";
import {Divider, Loader, Popover, Stack, Table, Whisper} from "rsuite";
import moment from "moment-timezone";
import StatsLabel from "../statistics/time-aggregation/stats-label";

const {Column, HeaderCell, Cell} = Table

@customElement("contingent-pool-display")
@noView()
@inject(Element)
export default class ContingentPoolDisplay {
    @bindable({defaultBindingMode: bindingMode.oneTime}) pool
    @bindable({defaultBindingMode: bindingMode.oneTime}) itinerary

    constructor(container) {
        this.container = container
    }

    attached() {
        render(
            <Provider store={store}>
                <Pool pool={this.pool} itinerary={this.itinerary}/>
            </Provider>,
            this.container
        )
    }
}

/**
 * @type {({pool: ?string, itinerary: ?string}) => any}
 */
function Pool({pool, itinerary}) {
    const {isLoading, data} = useContingentPoolQuery({pool, itinerary})

    if (isLoading) {
        return (
            <Loader size="md"/>
        )
    }

    return (
        <Table virtualized data={data.rows} autoHeight headerHeight={50}>
            <Column fullText fixed>
                <HeaderCell>Zimmer</HeaderCell>
                <Cell>{({occupancyType}) => data.occupancyTypes[occupancyType]}</Cell>
            </Column>

            {1 < Object.keys(data.pools).length && (
                <Column fullText fixed>
                    <HeaderCell>Kontingent</HeaderCell>
                    <Cell>{({pool}) => <StatsLabel label={data.pools[pool]}/>}</Cell>
                </Column>
            )}

            {1 < Object.keys(data.itineraries).length && (
                <Column fullText fixed>
                    <HeaderCell>Reise</HeaderCell>
                    <Cell>{({itinerary}) => <StatsLabel label={data.itineraries[itinerary]}/>}</Cell>
                </Column>
            )}

            {1 < Object.keys(data.accommodations).length && (
                <Column fullText fixed>
                    <HeaderCell>Unterkunft</HeaderCell>
                    <Cell>{({accommodation}) => <StatsLabel
                        label={data.accommodations[accommodation]}/>}</Cell>
                </Column>
            )}

            {data.dates.map((date, idx) => {
                const m = moment(date)
                const day = m.format("DD")
                const month = m.format("MM")
                const style = 0 !== idx && "01" !== day ? {} : {
                    borderLeft: "1px solid #888",
                    paddingLeft: "4px"
                }

                return (
                    <Column key={date} width={50}>
                        <HeaderCell>
                            <div style={style}>
                                {0 === idx || "01" === day ? (
                                    <b>{0 === idx || "01" === month ? m.format("YYYY") : m.format("MMM")}</b>
                                ) : (
                                    <small>{m.format("dd")}</small>
                                )}
                                <br/>
                                {day}
                            </div>
                        </HeaderCell>
                        <Cell>
                            {row => row[date] ? (
                                <ContingentCell
                                    date={date}
                                    contingent={row[date]}
                                    participants={data.participants}
                                    orders={data.orders}
                                    style={style}
                                />
                            ) : (
                                <div style={style}>
                                    –
                                </div>
                            )}
                        </Cell>
                    </Column>
                )
            })}
        </Table>
    )
}

const ContingentCell = ({date, contingent, participants, orders, style}) => (
    <Whisper placement="autoVerticalEnd" speaker={
        <Popover >
            <Details date={date} contingent={contingent} orders={orders}
                     participants={participants}/>
        </Popover>
    }>
        <div style={style}>
            {contingent.free}<small>/{contingent.max}</small>
        </div>
    </Whisper>
)

function Details({date, contingent: {max, free, participants: p}, participants, orders}) {
    const details = [
            ["Datum", moment(date).format("L")],
            ["Kontingent", max],
            ["Belegt", max - free],
            ["Frei", free]
        ],
        occupancy = !p.length ? null : p.map(id => {
            const [lastName, firstName, order] = participants[id] ?? []
            return {id, lastName, firstName, order: orders[order]}
        })

    return (
        <Stack direction="column" divider={<Divider/>} alignItems="stretch" spacing={0}>
            <Stack.Item>
                <Stack direction="column" alignItems="stretch">
                    {details.map(([label, value]) => (
                        <Stack.Item key={label}>
                            <Stack direction="row" justifyContent="space-between" spacing={4}>
                                <Stack.Item grow={1}>{label + ":"}</Stack.Item>
                                <Stack.Item grow={1}>{value}</Stack.Item>
                            </Stack>
                        </Stack.Item>
                    ))}
                </Stack>
            </Stack.Item>

            {occupancy && (
                <Stack.Item>
                    <Stack direction="column" alignItems="stretch">
                        {occupancy.map(({id, lastName, firstName, order}) => (
                            <Stack.Item key={id}>
                                <Stack direction="row" justifyContent="space-between" spacing={4}>
                                    <Stack.Item>{`${lastName}, ${firstName}`}</Stack.Item>
                                    <Stack.Item><StatsLabel label={order}/></Stack.Item>
                                </Stack>
                            </Stack.Item>
                        ))}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    )
}
