import {bindable, customElement, inject} from "aurelia-framework";
import {FormServiceFactory} from "../form/service/form-service-factory";
import {UserClient} from "../api/user-client";
import {DialogService} from "aurelia-dialog";
import Client from "../api/client";
import {EventAggregator} from "aurelia-event-aggregator";

@inject(FormServiceFactory, UserClient, DialogService, Client, EventAggregator)
@customElement('sio-tourism-contract-accommodation-room-season-prices')
export class RoomSeasonPrices {
    @bindable field;
    @bindable({defaultBindingMode: 2}) value;

    seasons = [];
    rooms = [];
    commission;
    contractCurrency;

    constructor(formServiceFactory, user, dialogService, client, ea) {
        this.formServiceFactory = formServiceFactory;
        this.dialogService = dialogService;
        this.client = client;
        this.ea = ea;
    }

    _getControlUID() {
        return 'sio-tourism-contract-accommodation-room-season-prices-' + this.field.property;
    }

    attached() {
        this.viewChange = true;
        this.value = this.updateModelValue();
    }

    async bind() {
        this._processModelValue();
    }

    detached() {
    }

    valueChanged(newValue) {
        //Important check here, as a view change also triggers this callback and this way we would have a loop
        if (this.viewChange) {
            this.viewChange = false;
            return;
        }

        this._processModelValue();
    }

    _processModelValue() {
        let rooms = [];

        this.contractCurrency = this.value?.contractCurrency ?? ''

        this.commission = this.value?.commission ?? 0;

        for (let i = 0; i < this.value?.roomCategories?.length; i++) {
            let room = this.value.roomCategories[i];

            let priceType = room.priceType ?? 'amount';

            if (room.baseType) {
                priceType = 'amount';
            }

            rooms.push(
                {
                    code: room.code,
                    baseType: room.baseType,
                    priceType: priceType,
                    seasonPriceLines: room.seasonPriceLines,
                }
            );
        }

        let seasonPriceLines = rooms[0]?.seasonPriceLines ?? [];

        this.seasons = seasonPriceLines.map((seasonPriceLine) => {
            return seasonPriceLine.season;
        })

        this.rooms = rooms;
    }

    updateAndSaveValue() {
        this.viewChange = true;

        this.value = this.updateModelValue();
    }

    updateModelValue() {

        let roomCategories = this.rooms.map((room) => {
            return {
                priceType: room.priceType,
                seasonPriceLines: room.seasonPriceLines.map(seasonPriceLine => {


                    if (room.priceType !== 'percentage') {
                        return {
                            amount: seasonPriceLine.amount
                        }
                    } else {
                        return {
                            percentage: seasonPriceLine.percentage
                        }
                    }

                }),
            }
        })

        return {
            commission: this.commission,
            roomCategories: roomCategories,
        };
    }
}
